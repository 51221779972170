import "./Login.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { storeAccessToken, storeRefreshToken } from "../../utils/jwt";
import { pageURL } from "../routes";

import axios, { AxiosError } from 'axios';
import LocalIcon from "../../components/Icon/Icon";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'

interface LoginResponse {
	access_token: string
	refresh_token: string
}

interface ErrorResponse {
	err_id?: string;
	message: string;
}

export default function Login() {
	// ------------------------- QUERY PARAMETERS -----------------------
	const [searchParams] = useSearchParams();
	const appSumoCode = searchParams.get("code");

	// ----------------------- STATES -----------------------
	const [loginUnderway, setLoginUnderway] = useState(false);
	const [googleLoginUnderWay, setGoogleLoginUnderWay] = useState(false);

	// ----------------------- REACT REFS -----------------------
	const emailElementRef = useRef<HTMLInputElement>(null);
	const passwordElementRef = useRef<HTMLInputElement>(null);
	const errorMessageTextRef = useRef<HTMLParagraphElement>(null);

	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Login | Abun"
	}, []);

	/* -------------- validation functions -------------- */
	function emailIsValid(value: string): boolean {
		return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
	}

	function passwordIsValid(value: string): boolean {
		return value.length >= 6
	}

	/* ------------------------------------------------- */

	/**
	 * Shows error message below the submit button
	 * @param msg - error message to show
	 */
	function displayErrorMessage(msg: string) {
		if (errorMessageTextRef.current) {
			errorMessageTextRef.current.classList.remove('is-hidden');
			errorMessageTextRef.current.innerText = msg;
		}
	}

	/**
	 * Hides error message below the submit button
	 */
	function removeErrorMessage() {
		if (errorMessageTextRef.current) {
			errorMessageTextRef.current.classList.add('is-hidden');
			errorMessageTextRef.current.innerText = "";
		}
	}

	function submitLoginData() {
		// clear all error messages
		removeErrorMessage();

		const email: string = emailElementRef.current?.value || "";
		const password: string = passwordElementRef.current?.value || "";

		if (!emailIsValid(email) || !passwordIsValid(password)) {
			displayErrorMessage("Please enter a valid email id and password");
			return;
		}

		setLoginUnderway(true);

		/* submit data to server */
		axios({
			method: "post",
			url: process.env.REACT_APP_DRF_DOMAIN + "/api/frontend/login/",
			responseType: 'json',
			data: {
				email: email,
				password: password,
				appsumo_code: appSumoCode
			}
		}).then(response => {
			// Logged in successfully. Redirect to next page.
			let result: LoginResponse = response.data;
			let accessToken: string = result.access_token;
			let refreshToken: string = result.refresh_token;

			// check to prevent "undefined" string being stored as the value
			if (accessToken && refreshToken) {
				storeAccessToken(accessToken);
				storeRefreshToken(refreshToken);
				return navigate(pageURL['createArticle']);
			} else {
				setLoginUnderway(false);
				displayErrorMessage("We are currently facing some issues. Please try again later.");
			}

		}).catch(err => {
			setLoginUnderway(false);
			const axiosError = err as AxiosError<ErrorResponse>;
			if (axiosError.response?.status === 401) {
				const errId = axiosError.response.data?.message || "Unknown error occurred.";
				displayErrorMessage(errId);
			} else {
				console.error(err);
				displayErrorMessage("We are currently facing some issues. Please try again later.");
			}
		});
	}


	const [password, setPassword] = useState("");
	const [type, setType] = useState('password');
	const [icon, setIcon] = useState(eyeOff);
	const handleToggle = () => {
		if (type === 'password') {
			setIcon(eye);
			setType('text')
		} else {
			setIcon(eyeOff)
			setType('password')
		}
	}

	return (
		<div className="login-wrapper">
			<div className="login-container">
				<h1 className="heading-text epilogue has-text-black">Login</h1>
				<h2 className="subtitle-text has-text-black bricolage">One Aim. More Organic Traffic to you, effortlessly.</h2>

				<div className="card login-card mt-5">
					<div className="card-content">
						<div className="content has-text-centered"
							onKeyDown={e => {
								if (e.key === 'Enter') {
									submitLoginData();
								}
							}}>
							<p ref={errorMessageTextRef} className="has-text-danger is-small has-text-centered is-hidden error-msg mb-0"></p>
							{/* ------------------ EMAIL ID ------------------ */}
							<div className="field mt-2">
								<div className="control">
									<input ref={emailElementRef}
										className="input is-medium"
										type="email"
										name="password"
										placeholder="Email ID" />
								</div>
							</div>
							{/* ------------------ PASSWORD ------------------ */}
							<div className="field mt-5">
								<div className="control">
									<input ref={passwordElementRef}
										className="input is-medium"
										type={type}
										name="password"
										placeholder="Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										autoComplete="current-password" />
									<span className="eye-icon" onClick={handleToggle}>
										<Icon icon={icon} size={25} />
									</span>
								</div>
							</div>
							<button className="button login-btn-border is-large is-responsive mt-5"
								style={{ borderRadius: "8px" }}
								disabled={loginUnderway || googleLoginUnderWay}
								onClick={submitLoginData}>
								{loginUnderway ? (
									<span>Logging In...&nbsp;&nbsp;<LocalIcon iconName={"spinner"} additionalClasses={["icon-white"]} /></span>
								) : <span>LOGIN →</span>}
							</button>
							<div className="divider-wrapper mt-4 mb-4">
								<span className="divider">OR</span>
							</div>
							<div className='connect-account'>
								<button
									disabled={loginUnderway || googleLoginUnderWay}
									onClick={() => {
										setGoogleLoginUnderWay(true);

										axios({
											method: "get",
											url: process.env.REACT_APP_DRF_DOMAIN + "/api/fontend/google-signup-login-auth/?signup=false",
											responseType: 'json',
										}).then((response) => {
											if (response.status === 200) {
												const auth_url = response.data.authorization_endpoint;
												window.location.href = auth_url;
											} else {
												setGoogleLoginUnderWay(false);
											}
										})

									}}
									className='button login-btn-border'>
									<LocalIcon iconName='google' />
									{
										googleLoginUnderWay ?
											<span className='ml-3'>
												Sign in with Google...&nbsp;&nbsp;<LocalIcon iconName={"spinner"} additionalClasses={["icon-white"]} />
											</span> :
											<span className='ml-3'>
												Sign in with Google →
											</span>
									}
								</button>
							</div>
						</div>
					</div>
				</div>

				<p className="has-text-white has-text-centered is-size-4 mt-6 bricolage">
					<div className="user-login">
						<Link to={appSumoCode ? `${pageURL['appSumoSignUp']}?code=${appSumoCode}` : pageURL['signup']} className="has-text-black is-underlined">Create New Account</Link>
						<span className="has-text-black"><span className="divider">|</span></span>
						<Link to={pageURL['forgotPassword']} className="has-text-black is-underlined">Forgot Password?</Link>
					</div>
				</p>
			</div>
		</div>
	)
}
