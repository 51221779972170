import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useMutation } from "@tanstack/react-query";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Icon from "../../components/Icon/Icon";
import { saveSettingsMutation } from "../../utils/api";

export type FeatureImageTemplates = {
	template_id: string,
	template_name: string,
	sample_image_url: string
	tool_tip: string
	label: string
	order: number
}

export type KeywordStrategies = 'volume' | 'cpc' | 'competition';
export type ImageSource = 'no_image' | 'google' | 'unsplash' | 'ai_image_generation';

interface ImageSettingsProps {
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	featuredImageTemplates: Array<FeatureImageTemplates>
	featuredImageIsEnable: boolean | undefined
	selectedTemplate: string | null
	imagesFileFormat: string
	keywordStrategy: KeywordStrategies
	imageSource: ImageSource
	currentPlanName: string
	// aiGeneratedImageStylePreference: string
	setFeaturedImageTemplates: Dispatch<SetStateAction<Array<FeatureImageTemplates>>>
	setSelectedTemplate: Dispatch<SetStateAction<string | null>>
	setFeaturedImageIsEnable: Dispatch<SetStateAction<boolean | undefined>>
	updatePageData: () => void
	setUnsavedChanges: Dispatch<SetStateAction<boolean>>
}

export default function ImageSettings(props: ImageSettingsProps) {
	const {
		selectedTemplate,
		featuredImageTemplates,
		featuredImageIsEnable,
		setSelectedTemplate,
		setFeaturedImageIsEnable
	} = props;

	// --------------------- STATES ---------------------
	const [
		imageSource,
		setImageSource
	] = useState<ImageSource>(props.imageSource);

	const [
		imageFilesFormat,
		setImagesFileFormat
	] = useState<string>(props.imagesFileFormat);

	const [haveUnsavedChanges, setHaveUnsavedChanges] = useState<boolean>(false);

	// ---------------------------- MUTATIONS ----------------------------
	const saveSettings = useMutation(saveSettingsMutation);


	// ----------------------------- FUNCTIONS ---------------------------

	function changeFeatureImageTemplate(newValue: string) {
		if (newValue === selectedTemplate) return;
		setHaveUnsavedChanges(true);
		props.errorAlertRef.current?.close();
		setSelectedTemplate(newValue);

		if (selectedTemplate === "") {
			props.errorAlertRef.current?.show("Please select a feature image template.");
			setTimeout(() => {
				try {
					if (props.errorAlertRef.current) {
						props.errorAlertRef.current.close();
					}
				} catch (e) { }
			}, 5000);
			return;
		}

		saveSettings.mutate({
			settingsToSave: [
				{ settingName: 'feature_image', settingValue: newValue },
				{ settingName: 'feature_image_required', settingValue: featuredImageIsEnable },
			]
		}, {
			onSuccess: () => {
				props.updatePageData();
				try {
					props.setUnsavedChanges(false);
					setHaveUnsavedChanges(false);
					props.successAlertRef.current.show("Changes Saved!");
					setTimeout(() => {
						try {
							if (props.successAlertRef.current) {
								props.successAlertRef.current.close();
							}
						} catch (e) { }
					}, 3000);
				} catch (e) { }
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
				setTimeout(() => {
					try {
						if (props.errorAlertRef.current) {
							props.errorAlertRef.current.close();
						}
					} catch (e) { }
				}, 5000);
			}
		});

	}

	function changeImageFileFormat(newValue: string) {
		props.errorAlertRef.current?.close();
		setImagesFileFormat(newValue);

		saveSettings.mutate({
			settingsToSave: [
				{ settingName: 'images_file_format', settingValue: newValue },
			]
		}, {
			onSuccess: () => {
				props.updatePageData();
				try {
					props.setUnsavedChanges(false);
					setHaveUnsavedChanges(false);
					props.successAlertRef.current.show("Changes Saved!");
					setTimeout(() => {
						try {
							if (props.successAlertRef.current) {
								props.successAlertRef.current.close();
							}
						} catch (e) { }
					}, 3000);
				} catch (e) { }
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
				setTimeout(() => {
					try {
						if (props.errorAlertRef.current) {
							props.errorAlertRef.current.close();
						}
					} catch (e) { }
				}, 5000);
			}
		});
	}


	function changeImageSource(newValue: ImageSource) {
		props.errorAlertRef.current?.close();
		if (newValue === imageSource) return;
		setImageSource(newValue);

		saveSettings.mutate({
			settingsToSave: [
				{ settingName: 'image_source', settingValue: newValue },
			]
		}, {
			onSuccess: () => {
				props.updatePageData();
				try {
					props.setUnsavedChanges(false);
					setHaveUnsavedChanges(false);
					props.successAlertRef.current.show("Changes Saved!");
					setTimeout(() => {
						try {
							if (props.successAlertRef.current) {
								props.successAlertRef.current.close();
							}
						} catch (e) { }
					}, 3000);
				} catch (e) { }
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
				setTimeout(() => {
					try {
						if (props.errorAlertRef.current) {
							props.errorAlertRef.current.close();
						}
					} catch (e) { }
				}, 5000);
			}
		});
	}

	function UpdateFeaturedReq(){
		const newValue = !featuredImageIsEnable;
        setFeaturedImageIsEnable(newValue);
		saveSettings.mutate(
			{
				settingsToSave: [
					{ settingName: 'feature_image_required', settingValue: newValue },
				],
			},
			{
				onSuccess: () => {
					props.setUnsavedChanges(false);
					props.successAlertRef.current?.show("Changes Saved!");
					setTimeout(() => {
						props.successAlertRef.current?.close();
					}, 3000);
				},
				onError: () => {
					props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
					setTimeout(() => {
						props.errorAlertRef.current?.close();
					}, 5000);
				},
			}
		);
	}

	function CustomizedSwitch() {
		const IOSSwitch = styled((props: SwitchProps) => (
			<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
		))(({ theme }) => ({
			width: 42,
			height: 26,
			padding: 0,
			'& .MuiSwitch-switchBase': {
				padding: 0,
				margin: 2,
				transitionDuration: '300ms',
				'&.Mui-checked': {
					transform: 'translateX(16px)',
					color: '#fff',
					'& + .MuiSwitch-track': {
						backgroundColor: '#65C466',
						opacity: 1,
						border: 0,
						...theme.applyStyles('dark', {
							backgroundColor: '#2ECA45',
						}),
					},
					'&.Mui-disabled + .MuiSwitch-track': {
						opacity: 0.5,
					},
				},
				'&.Mui-focusVisible .MuiSwitch-thumb': {
					color: '#33cf4d',
					border: '6px solid #fff',
				},
				'&.Mui-disabled .MuiSwitch-thumb': {
					color: theme.palette.grey[100],
					...theme.applyStyles('dark', {
						color: theme.palette.grey[600],
					}),
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.7,
					...theme.applyStyles('dark', {
						opacity: 0.3,
					}),
				},
			},
			'& .MuiSwitch-thumb': {
				boxSizing: 'border-box',
				width: 22,
				height: 22,
			},
			'& .MuiSwitch-track': {
				borderRadius: 26 / 2,
				backgroundColor: '#E9E9EA',
				opacity: 1,
				transition: theme.transitions.create(['background-color'], {
					duration: 500,
				}),
				...theme.applyStyles('dark', {
					backgroundColor: '#39393D',
				}),
			},
		}));

		return (
			<FormGroup>
				<FormControlLabel
					control={<IOSSwitch sx={{ m: 1 }} defaultChecked={featuredImageIsEnable} onChange={() => UpdateFeaturedReq()} />}
					label=""
				/>
			</FormGroup>
		);
	}

	function isTemplateDisabled(template: FeatureImageTemplates, currentPlan: string) {
		// Allow user to use premium template if he/she has already selected a premium template
		const selectedTemplateData = featuredImageTemplates.filter((template) => (template.template_id === selectedTemplate))[0];
		if (selectedTemplateData.label === 'Premium') {
			return false;
		} else if (currentPlan === 'Basic' || currentPlan === 'LTD' || currentPlan === 'Trial') {
			return template.label === 'Premium';
		}
		return false;
	}

	// on tab change, check if there are unsaved changes
	useEffect(() => {
		const hasUnsavedChanges = imageFilesFormat !== props.imagesFileFormat ||
			haveUnsavedChanges;
		props.setUnsavedChanges(hasUnsavedChanges);
	}, [imageFilesFormat, haveUnsavedChanges, props]);

	// =====================================================
	// --------------------- MAIN CODE ---------------------
	// =====================================================
	return (
		<>
			<div className="card setting-sections p-5">
				{/* -=-=-=-=-=-=-=-=-=-=-= Text or Without Text -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section is-flex is-flex-direction-row"}>
					<CustomizedSwitch
						aria-label="Enable featured images"
					/>
					<div>
						<p className={"settings-section-title"}>
							Featured Images: Choose your Style (Experimental)
						</p>
						<p className="text-sm text-gray-500">Choose the style of featured images do you want for your articles.</p>
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= Feature Image Templates -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={`settings-section feature-image-templates`}>
					<div className={"templates-container is-flex is-align-items-center mt-5"}>
						{featuredImageIsEnable &&
							featuredImageTemplates?.sort((a, b) => {
								return a.order - b.order;
							})?.map((template, index) => {
								const isDisabled = isTemplateDisabled(template, props.currentPlanName);
								return (
									<div
										className={`template-overlay ${isDisabled ? 'show-overlay' : ''}`}
										data-tooltip-id={`disabled-${template.template_id}`}
										data-tooltip-content="This template is only available for Pro and Pro Max users. Please upgrade your plan.">
										<div key={index} className={`control template mt-1 ${isDisabled ? 'opacity-50' : ''}`}>
											<label className={`radio is-flex is-align-items-center ${isDisabled ? 'cursor-not-allowed' : ''}`} style={{ flexDirection: "column" }}>
												<span className={"settings-radio-item-title"}>
													<img src={template.sample_image_url}
														alt={template.template_id}
														className={`${selectedTemplate === template.template_id ? "selected-image" : ""} ${isDisabled ? 'filter grayscale' : ''}`} />
												</span>
												<div className="is-flex ml-4" style={{ marginTop: "0.5rem", width: "100%", alignItems:'center' }}>
													<input type="radio"
														className={`mr-4`}
														name={"feature-image"}
														checked={selectedTemplate === template.template_id}
														disabled={isDisabled}
														onChange={() => changeFeatureImageTemplate(template.template_id)} />
													<p style={selectedTemplate === template.template_id ? { color: "#6969eb" } : {}}>{template.template_name}</p>
													{(template.tool_tip && !isDisabled) &&
														<div data-tooltip-id={template.template_name} data-tooltip-content={template.tool_tip}>
															<Icon iconName="question-phosphor" additionalClasses={["ml-2"]} style={{ border: "none", width: "0.8em" }} />
															<Tooltip style={{ position: "absolute", zIndex: "1000" }} id={template.template_name} />
														</div>
													}
													<div className="label-container">
														<span className="uncollapsed-tag tag is-info is-light is-rounded" style={{ marginLeft: '1.1em', padding: '6px 13px', fontSize: '14px' }}>
															{template.label === "Premium" && <span>👑  {template.label}</span>}
															{template.label === "Basic" && <span>🌱  {template.label}</span>}
															{template.label === "Pro" && <span>⚡  {template.label}</span>}
														</span>
													</div>
												</div>
											</label>
										</div>
										{isDisabled && (
											<Tooltip style={{ position: "absolute", zIndex: "1000" }} id={`disabled-${template.template_id}`} />
										)}
									</div>
								);
							})
						}
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= Image Source -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section"} style={featuredImageIsEnable ? { marginTop: "4rem" } : { marginTop: "2rem" }}>
					<h5 className={"settings-section-title"}>
						Which image source do you want to use in your articles?
					</h5>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'ai_image_generation'}
								onChange={() => changeImageSource('ai_image_generation')} />
							<span className={"settings-radio-item-title"}>AI Generated</span>
						</label>
						<p className={"settings-radio-item-description"}>
							AI Generated images will be used in the Article.
						</p>
					</div>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'google'}
								onChange={() => changeImageSource('google')} />
							<span className={"settings-radio-item-title"}>Google Images</span>
						</label>
						<p className={"settings-radio-item-description"}>
							Get images from Google. Using this option might require you to get the necessary permission(s)
							from the photo owner before using them in your article.
						</p>
					</div>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'no_image'}
								onChange={() => changeImageSource('no_image')} />
							<span className={"settings-radio-item-title"}>No Image</span>
						</label>
						<p className={"settings-radio-item-description"}>
							No images will be used in the Article.
						</p>
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= Image File Format -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section mt-6"}>
					<h5 className={"settings-section-title"}>
						Images File Format:
					</h5>
					<div className="is-flex control mt-4">
						<div className="mr-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imageFilesFormat === "webp"}
									onChange={() => changeImageFileFormat("webp")} />
								<span className={"settings-radio-item-title"}>WebP</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imageFilesFormat === "png"}
									onChange={() => changeImageFileFormat("png")} />
								<span className={"settings-radio-item-title"}>PNG</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imageFilesFormat === "jpeg"}
									onChange={() => changeImageFileFormat("jpeg")} />
								<span className={"settings-radio-item-title"}>JPG</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			{/* -=-=-=-=-=-=-=-=-=-=-= Save All Settings Button -=-=-=-=-=-=-=-=-=-=-= */}
			{/* <div className="save-changes-section mt-4">
				<button
					className={`button is-primary ${saveSettings.isLoading ? 'is-loading' : ''}`}
					onClick={saveAllImageSettings}
				>
					Save Changes
				</button>
			</div> */}
		</>
	)
}
