import React, { createContext, useState, useEffect, ReactNode } from "react";
import { getLoggedInPageData } from "../routes"
import { BasePageData } from "../Base/Base";
import { useRouteLoaderData } from "react-router-dom";

type SurveyContextType = {
    showSurvey: boolean;
    setShowSurvey: React.Dispatch<React.SetStateAction<boolean>>;
    surveyCompleted: boolean;
    setSurveyCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with a default value
export const SurveyContext = createContext<SurveyContextType | undefined>(undefined);

// Define the props for the provider component
interface SurveyProviderProps {
    children: React.ReactNode;
    disableSurvey?: boolean;
}

export const SurveyProvider: React.FC<SurveyProviderProps> = ({ children, disableSurvey = false }) => {
    // ---------------------------- BASE PAGE DATA ----------------------------
    const basePageData: BasePageData = useRouteLoaderData("base") as BasePageData;

    // ---------------------------- STATES ----------------------------
    const [showSurvey, setShowSurvey] = useState<boolean>(false);
    const [isSurveyCompleted, setSurveyCompleted] = useState<boolean>(basePageData.survey_completed);

    // ---------------------------- EFFECTS ----------------------------
    useEffect(() => {
        // If the survey is completed, skip the logic to show the survey
        if (isSurveyCompleted) {
            return;
        }

        const firstVisitTime = localStorage.getItem(`first-visit-time-${basePageData.user_id}`);

        if (!firstVisitTime) {
            const now = Date.now();
            localStorage.setItem(`first-visit-time-${basePageData.user_id}`, now.toString());
        }

        const elapsedTime = Date.now() - Number(localStorage.getItem(`first-visit-time-${basePageData.user_id}`));
        const remainingTime = 30 * 60 * 1000 - elapsedTime;

        if (remainingTime <= 0) {
            setShowSurvey(true);
        } else {
            const timer = setTimeout(() => {
                setShowSurvey(true);
            }, remainingTime);

            return () => clearTimeout(timer); // Clean up the timer
        }
    }, [isSurveyCompleted]);

    useEffect(() => {
        if (disableSurvey) {
            setShowSurvey(false);
        }
    }, [disableSurvey]);

    return (
        <SurveyContext.Provider value={{ showSurvey, setShowSurvey, surveyCompleted: isSurveyCompleted, setSurveyCompleted }}>
            {children}
        </SurveyContext.Provider>
    );
};