import { pageURL } from '../routes';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { googleLoginAndSignupAuth } from "../../utils/api";
import { useMutation } from "@tanstack/react-query";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Icon from '../../components/Icon/Icon';
import { storeAccessToken, storeRefreshToken } from "../../utils/jwt";

import "./GoogleLoginAndSignup.scss";


export default function GoogleLoginAndSignup(props: { signup: boolean }) {
    const query = useQuery();
    const navigate = useNavigate();

    // --------------------------- MUTATIONS ---------------------------
    const googleLoginAndSignupAuthMut = useMutation(googleLoginAndSignupAuth);

    // --------------------------- FUNCTIONS ---------------------------
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function getUserCountry(): Promise<string> {
        return new Promise((resolve, reject) => {
            const endpoint = "https://api.ipdata.co/?fields=country_name&api-key=4346a4c48105f4c24084f18b6badc1ed363ab8bdd96420a2b070c10f";
            const xhr = new XMLHttpRequest();

            xhr.open('GET', endpoint, true);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        try {
                            const response = JSON.parse(xhr.responseText);
                            resolve(response.country_name as string);
                        } catch (_) {
                            reject('Failed to fetch the country name from ipdata.');
                        }
                    } else {
                        reject('HTTP error! status: ' + xhr.status);
                    }
                }
            };

            xhr.onerror = function () {
                reject('Network error');
            };
            xhr.send();
        })
    }

    function getAccessTokens(country = "United States", on_error_redirect_to = "login" || "signup") {
        googleLoginAndSignupAuthMut.mutate({
            state: query.get("state"),
            code: query.get("code"),
            scope: query.get("scope"),
            country: country,
            signup: on_error_redirect_to === "signup"
        }, {

            onSuccess: (data) => {
                let responseData = (data as any)["data"];

                if (responseData.success) {
                    // get the tokens from response
                    let accessToken: string = responseData.access_token;
                    let refreshToken: string = responseData.refresh_token;

                    // store the tokens
                    storeAccessToken(accessToken);
                    storeRefreshToken(refreshToken);

                    // navigate to keyword research page
                    navigate(pageURL['keyword-research']);
                } else {
                    console.log("Failed to fetch the required data.");

                    setTimeout(() => {
                        navigate(pageURL[on_error_redirect_to]);
                    }, 3000);

                }

            },

            onError: (error: Error) => {
                console.log(error);

                setTimeout(() => {
                    navigate(pageURL[on_error_redirect_to]);
                }, 3000);
            }
        })
    }

    // --------------------------- EFFECTS ---------------------------
    useEffect(() => {
        const on_error_redirect_to = props.signup ? "signup" : "login";

        getUserCountry().then(country => {
            getAccessTokens(country, on_error_redirect_to);
        }).catch((error: Error) => {
            console.log(error.message);
            getAccessTokens("United States", on_error_redirect_to);
        })
    }, []);

    if (props.signup) {
        return (
            <div className="signup-container">
                <h1 className="heading-text epilogue">Get Started with Abun</h1>
                <h2 className="subtitle-text bricolage">One Aim. More Organic Traffic to you, effortlessly.</h2>

                <div className="card signup-card mt-5">
                    <div className="card-content">
                        <div className="content has-text-centered">
                            {/* ------------------ USERNAME ------------------ */}
                            <div className="field">
                                <div className="control show-error-message-above" data-err-msg="">
                                    <input
                                        className="input is-medium"
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Full Name" />
                                </div>
                            </div>
                            {/* ------------------ EMAIL ID ------------------ */}
                            <div className="field" style={{ marginTop: "2rem" }}>
                                <div className="control show-error-message-below" data-err-msg="">
                                    <input
                                        className="input is-medium"
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Email ID" />
                                </div>
                            </div>
                            {/* ------------------ PASSWORD ------------------ */}
                            <div className="field" style={{ marginTop: "2rem" }}>
                                <div className="control show-error-message-below" data-err-msg="">
                                    <input
                                        className="input is-medium"
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Password" />
                                </div>
                            </div>
                            <p className="is-size-7 mt-5" style={{ color: "#5D5E98" }}>
                                By signing up you
                                agree to our <a href="https://abun.com/terms-conditions/" rel={"noreferrer"} target={"_blank"}>Terms & Conditions</a>&nbsp;
                                and <a href="https://abun.com/privacy-policy/" rel={"noreferrer"} target={"_blank"}>Privacy Policy</a>.
                            </p>
                            <button
                                className="button signup-btn-border is-large is-responsive mt-5 show-error-message-below"
                                style={{ borderRadius: "8px", color: "#000" }}
                                disabled={true}>
                                <span>SIGNUP →</span>
                            </button>
                            <div className="divider-wrapper mt-4 mb-4">
                                <span className="divider">OR</span>
                            </div>
                            <div className='connect-account'>
                                <button
                                    disabled={true}
                                    className='button signup-btn-border'>
                                    <Icon iconName='google' />
                                    <span className='ml-3'>
                                        Sign in with Google...&nbsp;&nbsp;<Icon iconName={"spinner"} additionalClasses={["icon-white"]} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="has-text-black has-text-centered is-size-4 mt-6 mb-3 bricolage">
                    Already have an Account? <Link to={pageURL['login']} className="has-text-black is-underlined">Login</Link>
                </p>
            </div>
        )
    } else {
        return (
            <div className="login-container">
                <h1 className="heading-text epilogue has-text-black mt-6">Login</h1>
                <h2 className="subtitle-text has-text-black bricolage">One Aim. More Organic Traffic to you, effortlessly.</h2>

                <div className="card login-card mt-5">
                    <div className="card-content">
                        <div className="content has-text-centered">
                            {/* ------------------ EMAIL ID ------------------ */}
                            <div className="field mt-2">
                                <div className="control">
                                    <input
                                        className="input is-medium"
                                        type="email"
                                        name="password"
                                        placeholder="Email ID" />
                                </div>
                            </div>
                            {/* ------------------ PASSWORD ------------------ */}
                            <div className="field mt-5">
                                <div className="control">
                                    <input
                                        className="input is-medium"
                                        type="password"
                                        name="password"
                                        placeholder="Password" />
                                </div>
                            </div>
                            <button className="button login-btn-border is-large is-responsive mt-5"
                                style={{ borderRadius: "8px" }}
                                disabled={true}>
                                <span>LOGIN →</span>
                            </button>
                            <div className="divider-wrapper mt-4 mb-4">
                                <span className="divider">OR</span>
                            </div>
                            <div className='connect-account'>
                                <button
                                    disabled={true}
                                    className='button login-btn-border'>
                                    <Icon iconName='google' />
                                    <span className='ml-3'>
                                        Sign in with Google...&nbsp;&nbsp;<Icon iconName={"spinner"} additionalClasses={["icon-white"]} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="has-text-white has-text-centered is-size-4 mt-6 bricolage">
                    <Link to={pageURL['signup']} className="has-text-black is-underlined">Create New Account</Link>
                    &nbsp;&nbsp;<span className="has-text-black">|</span>&nbsp;&nbsp;
                    <Link to={pageURL['forgotPassword']} className="has-text-black is-underlined">Forgot Password?</Link>
                </p>
            </div>
        )
    }
}