import "./InternalLinks.scss";
import { useQuery, useMutation } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import {
    getScrapedWebPages, addSitemap, addInternalLink,
    findWebsiteSitemaps, getWebsiteAnalysisStatsQueryFn,
    reScrapeWebsitePages,
    scrapeMoreWebsitePages
} from "../../utils/api";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunButton from "../../components/AbunButton/AbunButton";
import { ConnectWebsite } from "../KeywordsResearchV2/KeywordResearch";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import { pageURL } from "../routes";
import Icon from "../../components/Icon/Icon";
import { BasePageData } from "../Base/Base";

interface InternalLink {
    url: string;
    title: string;
    summary: string;
    lastScanned: string;
}

interface ServerData {
    website_connected: boolean
    sitemap_urls: Array<string>
    domain: string
    is_crawling: boolean
    finding_sitemaps: boolean
    has_more_pages: boolean
}

interface WebsiteAanalysisStats {
    total_pages: number,
    pages_scanned: number,
    progress: number,
    estimated_time_left: number,
    steps: {
        crawling: string,
        analyzing: string,
        generating: string
    },
    is_analysis_complete: boolean,
    time_display: string
}

export default function InternalLinks() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];
    const isProduction = process.env.REACT_APP_DRF_DOMAIN === "https://api.abun.com";

    // ----------------------------- LOADER -----------------------------
    const pageData: ServerData = useLoaderData() as ServerData;
    const basePageData: BasePageData = useRouteLoaderData("base") as BasePageData;

    // ----------------------- REFS -----------------------
    const successAlertRef = useRef<any>(null);
    const failAlertRef = useRef<any>(null);

    // -------------------------- NAVIGATOR --------------------------
    const navigate = useNavigate();

    // -------------------------- STATES --------------------------
    const [tableData, setTableData] = useState<Array<InternalLink>>([]);
    const [isCrawling, setIsCrawling] = useState(pageData.is_crawling);
    const [sitemapUrl, setSitemapUrl] = useState(pageData.sitemap_urls[0] || "");
    const [internalLink, setInternalLink] = useState("");
    const [showAddInternalLinkModal, setShowAddInternalLinkModal] = useState(false);
    const [showConnectWebsiteModal, setShowConnectWebsiteModal] = useState(!pageData.website_connected);
    const [showSitemapUrlModal, setShowSitemapUrlModal] = useState(pageData.sitemap_urls.length === 0 && !pageData.finding_sitemaps && basePageData.user_verified);
    const [isLoading, setIsLoading] = useState(pageData.finding_sitemaps);
    const [loadingDataText, setLoadingDataText] = useState(pageData.finding_sitemaps ? "Finding Sitemaps..." : "Loading Data...");
    const [websiteAnalysisStats, setWebsiteAnalysisStats] = useState<WebsiteAanalysisStats>();

    // -------------------------- QUERIES --------------------------
    const { isFetching, isError, data, refetch } = useQuery(getScrapedWebPages());
    const {
        data: websiteAnalysisStatsData,
    } = useQuery({
        queryKey: ['websiteAnalysisStats'],
        queryFn: () => getWebsiteAnalysisStatsQueryFn(),
        refetchInterval: 5000,
        refetchIntervalInBackground: false,
        enabled: isCrawling,
    });

    // -------------------------- MUTATIONS --------------------------
    const addSiteMapMutation = useMutation(addSitemap);
    const addInternalLinkMutation = useMutation(addInternalLink);
    const reScrapeWebsitePagesMutation = useMutation(reScrapeWebsitePages);
    const scrapeMoreWebsitePagesMutation = useMutation(scrapeMoreWebsitePages);

    // ----------------------- EFFECTS -----------------------
    useEffect(() => {
        document.title = "Internal Links | Abun";
    }, []);

    useEffect(() => {
        if (showSitemapUrlModal) {
            setIsLoading(true);
            setLoadingDataText("Finding Sitemaps...");

            // Try to find the sitemap before showing the modal
            findWebsiteSitemaps().then((response) => {
                const responseData: any = response.data;
                if (responseData.success) {
                    setShowSitemapUrlModal(false);
                    setIsCrawling(true);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, []);

    useEffect(() => {
        if (data) {
            setTableData((data as any)['data']['web_pages']);
        }
    }, [data]);

    useEffect(() => {
        if (websiteAnalysisStatsData && (websiteAnalysisStatsData as any)['data']) {
            setWebsiteAnalysisStats((websiteAnalysisStatsData as any)['data']['stats']);
        }
    }, [websiteAnalysisStatsData]);

    useEffect(() => {
        if (websiteAnalysisStats && websiteAnalysisStats.is_analysis_complete) {
            // Refresh the page when analysis is complete
            window.location.reload();
        }
    }, [websiteAnalysisStats]);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<InternalLink>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: InternalLink) => row.url, {
            id: 'url',
            header: "URL",
            cell: props => <NavLink to={props.getValue()} target="_blank">{props.getValue()}</NavLink>,
            enableGlobalFilter: true,
            meta: { align: 'left' }
        }),
        // columnHelper.accessor((row: InternalLink) => row.title, {
        //     id: 'title',
        //     header: "Page Title",
        //     cell: info => info.getValue(),
        //     enableGlobalFilter: true,
        //     meta: { align: 'left' }
        // }),
        columnHelper.accessor((row: InternalLink) => row.summary, {
            id: 'summary',
            header: "Summary",
            cell: props => (
                <div className="internal-link-summary-cell">
                    <div className="summary-content">{props.getValue()}</div>
                    <div className="summary-tooltip">{props.getValue()}</div>
                </div>
            ),
            enableGlobalFilter: true,
            meta: { align: 'left' }
        }),
        columnHelper.accessor((row: InternalLink) => row.lastScanned, {
            id: 'lastScanned',
            header: "Last Scanned",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
            meta: { align: 'center' }
        }),
        // columnHelper.accessor((row: InternalLink) => row.isIndexed, {
        //     id: 'isIndexed',
        //     header: "Indexed by Google",
        //     cell: props => (
        //         <span className={`tag ${props.getValue() ? 'is-success' : 'is-warning'}`}>
        //             {props.getValue() ? 'Yes' : 'No'}
        //         </span>
        //     ),
        //     enableGlobalFilter: true,
        //     meta: { align: 'center' }
        // }),
    ];

    // ---------------------- HANDLERS ----------------------
    const handleAddInternalLink = () => {
        if (!internalLink.trim()) return;

        let url: URL | null;

        try {
            url = new URL(internalLink);
        } catch {
            failAlertRef.current?.show("Please enter a valid URL.");
            return;
        }

        if (url.hostname !== pageData.domain) {
            failAlertRef.current?.show("The internal link must match the website's domain.");
            return;
        }

        const linkExists = tableData.some(link => link.url === internalLink);
        if (linkExists) {
            failAlertRef.current?.show("This internal link already exists.");
            return;
        }

        addInternalLinkMutation.mutate(internalLink, {
            onSuccess: () => {
                setShowAddInternalLinkModal(false);
                setInternalLink(""); // Clear input
                successAlertRef.current?.show("Internal link added successfully!");
                refetch(); // Refresh the table data
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 5000);
            },
            onError: () => {
                failAlertRef.current?.show("Failed to add internal link. Please try again after some time.");
                setTimeout(() => {
                    failAlertRef.current?.close();
                }, 5000);
            }
        });
    };

    const handleAddSitemapUrl = () => {
        let url: URL | null;
        try {
            url = new URL(sitemapUrl);
        } catch {
            url = null;
        }

        if (!url) {
            failAlertRef.current?.show("Please enter a valid URL.");
        } else if (!sitemapUrl.trim()) {
            failAlertRef.current?.show("Please enter the sitemap URL.");
        } else if (!isValidSitemapUrl(url)) {
            failAlertRef.current?.show("The provided URL is not a valid sitemap URL. It should end with .xml or .txt");
        } else if (url.hostname !== pageData.domain) {
            failAlertRef.current?.show("The sitemap URL must match the website's domain.");
        } else {
            addSiteMapMutation.mutate(sitemapUrl, {
                onSuccess: (response: any) => {
                    const responseData: any = response.data;

                    if (responseData.success) {
                        setShowSitemapUrlModal(false);
                        setIsCrawling(true);
                        successAlertRef.current?.show("Sitemap URL added successfully!");
                        setTimeout(() => {
                            successAlertRef.current?.close();
                        }, 5000);
                    } else {
                        failAlertRef.current?.show(responseData.message);
                        setTimeout(() => {
                            failAlertRef.current?.close();
                        }, 5000);
                    }

                },
                onError: (response) => {
                    console.log(response)
                    failAlertRef.current?.show("Failed to add sitemap url. Please try again after some time.");
                    setTimeout(() => {
                        failAlertRef.current?.close();
                    }, 5000);
                }
            });
        }
    };

    // ---------------------- FUNCTIONS ----------------------
    function isValidSitemapUrl(parsedUrl: URL): boolean {
        try {
            return parsedUrl.pathname.endsWith('.xml') || parsedUrl.pathname.endsWith('.txt');
        } catch (error) {
            return false; // Return false if URL is not valid
        }
    }

    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isCrawling) {
        return (
            <div className="analysis-progress-container">
                <div className="analysis-card">
                    <h1 className="analysis-title">Website Analysis in Progress</h1>
                    <p className="analysis-subtitle">Our AI is analyzing your website & finding all the internal pages based on your sitemap.</p>

                    {
                        websiteAnalysisStats?.total_pages ?
                            <>
                                <div className="progress-bar">
                                    <div className="progress-fill" style={{ width: `${websiteAnalysisStats.progress}%` }}></div>
                                </div>

                                <div className="analysis-stats">
                                    <div className="stat-item">
                                        <h2>{websiteAnalysisStats.progress}%</h2>
                                        <p>Progress</p>
                                    </div>
                                    <div className="stat-item">
                                        <h2>{websiteAnalysisStats.time_display}</h2>
                                        <p>Minutes Left</p>
                                    </div>
                                    <div className="stat-item">
                                        <h2>{websiteAnalysisStats.pages_scanned || 0}</h2>
                                        <p>Pages Scanned</p>
                                    </div>
                                </div>
                            </>
                            :
                            <h4 className={"is-size-5 has-text-centered"}>
                                {loadingDataText}&nbsp;&nbsp;<Icon iconName="spinner" />
                            </h4>
                    }
                </div>

                <div className="analysis-steps-card">
                    <h3>Analysis Steps</h3>
                    <ul>
                        <li className={websiteAnalysisStats?.steps.crawling === 'completed' ? 'completed' : ''}>
                            Crawling website pages
                        </li>
                        <li className={websiteAnalysisStats?.steps.analyzing === 'completed' ? 'completed' : ''}>
                            Analyzing content structure
                        </li>
                        <li className={websiteAnalysisStats?.steps.generating === 'completed' ? 'completed' : ''}>
                            Generating optimization suggestions
                        </li>
                    </ul>
                </div>

                <div className="next-steps-card">
                    <h3>Next:</h3>
                    <p>Once we have scanned all of your websites internal pages, we will automatically link them in the articles based on how relevant they are.</p>
                    <br />
                    <p>We will also be showing up all the links. You can manually add more pages or rescan.</p>
                    <br />
                    <p>The amount of pages scanned is dependent on the plan you are on.</p>
                </div>
            </div>
        );
    } else if (isFetching || isLoading) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                {loadingDataText}
            </p>
        );
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Internal Links</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else if (!basePageData.user_verified) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Internal Links</h1>
                        <p className="has-text-centered is-size-5">
                            You will need to verify your email to use this feature.
                        </p>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <>
            <div className="tab-content w-100">
                <div className="card">
                    <div className="card-content">
                        <div className="mb-4">
                            <h1 className="has-text-centered" style={{ fontSize: "2em", fontWeight: "normal", width: "100%" }}>
                                Internal Links Manager
                            </h1>
                            <p className="mt-2 has-text-centered" style={{ fontSize: "1.2em", width: "100%" }}>
                                Manage and optimize your website's internal linking structure
                            </p>
                        </div>

                        <div className="is-flex is-flex-direction-row">
                            <AbunButton
                                className={"is-primary is-small mr-4"}
                                disabled={addInternalLinkMutation.isLoading}
                                type={"primary"} clickHandler={() => {
                                    setShowAddInternalLinkModal(true);
                                }}>
                                Add Internal Link
                            </AbunButton>

                            {
                                !pageData.is_crawling && !isProduction && basePageData.user_verified &&
                                <AbunButton
                                    className={"is-primary is-small mr-4"}
                                    disabled={reScrapeWebsitePagesMutation.isLoading}
                                    type={"primary"} clickHandler={() => {
                                        reScrapeWebsitePagesMutation.mutate(undefined, {
                                            onSuccess: () => {
                                                setIsCrawling(true);
                                                setLoadingDataText("Finding Sitemaps...")
                                            },
                                            onError: () => {
                                                failAlertRef.current?.show("Failed to send re-scrape website pages request. Please try again after some time.");
                                                setTimeout(() => {
                                                    failAlertRef.current?.close();
                                                }, 5000);
                                            }
                                        })
                                    }}>
                                    Re-Scrape
                                </AbunButton>
                            }

                            {
                                !pageData.is_crawling && pageData.has_more_pages && basePageData.currentPlanName !== 'Trial' &&
                                <AbunButton
                                    className={"is-primary is-small"}
                                    disabled={scrapeMoreWebsitePagesMutation.isLoading}
                                    type={"primary"} clickHandler={() => {
                                        scrapeMoreWebsitePagesMutation.mutate(undefined, {
                                            onSuccess: () => {
                                                setIsCrawling(true);
                                                setLoadingDataText("Finding Pages...")
                                            },
                                            onError: () => {
                                                failAlertRef.current?.show("Failed to send scrape more pages request. Please try again after some time.");
                                                setTimeout(() => {
                                                    failAlertRef.current?.close();
                                                }, 5000);
                                            }
                                        })
                                    }}>
                                    Scrape More
                                </AbunButton>
                            }
                        </div>

                        <AbunTable
                            tableContentName={"Internal Links"}
                            tableData={tableData}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={"No internal links data available."}
                            searchboxPlaceholderText={"Search internal links..."}
                        />
                    </div>
                </div>
            </div>

            {/* Add Internal Link Modal */}
            <AbunModal
                active={showAddInternalLinkModal}
                headerText={"Add Your Internal Link"}
                closeable={true}
                hideModal={() => setShowAddInternalLinkModal(false)}
            >
                <div className="has-text-centered">
                    <div className="field">
                        <div className="control">
                            <input
                                type="url"
                                className="input"
                                placeholder="Enter your internal link here..."
                                value={internalLink}
                                onChange={(e) => setInternalLink(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddInternalLink();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <AbunButton
                        type="success"
                        className="mt-4"
                        disabled={!internalLink.trim() || addInternalLinkMutation.isLoading} // Disable if input is empty
                        clickHandler={handleAddInternalLink}
                    >
                        {addInternalLinkMutation.isLoading ? "Adding..." : "Add Internal Link"}
                    </AbunButton>
                </div>
            </AbunModal>

            {/* Add Sitemap Url Modal */}
            <AbunModal
                active={showSitemapUrlModal}
                headerText={"Add Your Website Sitemap"}
                closeable={true}
                hideModal={() => {
                    navigate(pageURL["createArticle"]);
                }}
            >
                <div className="has-text-centered">
                    <div className="field">
                        <div className="control">
                            <input
                                type="url"
                                className="input"
                                placeholder="Enter your website sitemap url here..."
                                value={sitemapUrl}
                                onChange={(e) => setSitemapUrl(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddSitemapUrl();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <AbunButton
                        type="success"
                        className="mt-4"
                        disabled={!sitemapUrl.trim() || addSiteMapMutation.isLoading || !basePageData.user_verified} // Disable if input is empty
                        clickHandler={handleAddSitemapUrl}
                    >
                        {addSiteMapMutation.isLoading ? "Adding..." : "Add"}
                    </AbunButton>
                </div>
            </AbunModal>

            <SuccessAlert ref={successAlertRef} />
            <ErrorAlert ref={failAlertRef} />

            {showConnectWebsiteModal && (
                <ConnectWebsite
                    setShowConnectWebsiteModal={setShowConnectWebsiteModal}
                    failAlertRef={failAlertRef}
                    successAlertRef={successAlertRef}
                    navigateOrReload="reload"
                />
            )}
        </>
    );
}