import { useQuery } from "@tanstack/react-query";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AbunModal from "../../components/AbunModal/AbunModal";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";

import { useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import Survey from "../../components/Survey/Survey";
import { SurveyContext } from "../../pages/Login/SurveyContext";
import { generateSEOTitlesQueryFn } from "../../utils/api";
import type { CountryType } from "../../utils/constants/CountriesforSerp";
import "./ProgrammaticSeo.scss";

const ProgrammaticSeo = () => {
    // --------------------------- STATES ---------------------------
    const [nTitles, setNTitles] = useState("");
    const [pattern, setPattern] = useState("");
    const [examples, setExamples] = useState(["", "", ""]);
    const [showLoading, setShowLoading] = useState(false);
    const [triggerQuery, setTriggerQuery] = useState(false);
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isPatternValid, setIsPatternValid] = useState<boolean>(true);
    const [hasTouchedExamples, setHasTouchedExamples] = useState<boolean[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<CountryType>({
        location_code: 1,
        location_name: 'Global',
        country_iso_code: 'ZZ',
    });

    // -------------------------- SURVEY CONTEXT --------------------------
    const context = useContext(SurveyContext);
    const { showSurvey } = context ? context : { showSurvey: false }
    const isSmallScreen = useMediaQuery('(max-width:490px)');


    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNTitles(value);

        // Validate the input: it should be a positive number
        if (Number(value) > 0) {
            setIsValid(true); // Input is valid
        } else {
            setIsValid(false); // Input is invalid
        }
    };

    const handleBackClick = () => {
        navigate('/title-project');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setShowLoading(true);
        setTriggerQuery(true);
    };

    const handleRemoveExample = (index: number) => {
        const newExamples = examples.filter((_, i) => i !== index);
        const updatedTouched = [...hasTouchedExamples];
        setExamples(newExamples);
        updatedTouched.splice(index, 1);
        setHasTouchedExamples(updatedTouched);
    };

    const handleAddExample = () => {
        if (examples.length < 15) {
            setExamples([...examples, ""]);
            setHasTouchedExamples([...hasTouchedExamples, false]);            
        }
    };
    
    const handleBlur = (index) => {
        const updatedTouched = [...hasTouchedExamples];
        updatedTouched[index] = true; // Mark the field as touched
        setHasTouchedExamples(updatedTouched);
      };

    const validateExamples = () => {
        return examples.every((example) => example.trim() !== "");
      };

    const { data, error, isLoading, isFetching } = useQuery<any, Error>(
        ["generateSEOTitlesAPI", nTitles, pattern, examples, selectedLocation],
        () =>
            generateSEOTitlesQueryFn(
                parseInt(nTitles, 10),
                pattern,
                examples.filter((example) => example.trim() !== ""),
                selectedLocation,
            ),
        {
            enabled: triggerQuery,
            refetchOnWindowFocus: false,
            retry: 1,
            onSuccess: (responseData) => {
                setTriggerQuery(false);
                setShowLoading(false);
                // Show success message
                // setSuccessMessage("Titles successfully generated!");
                successAlertRef.current?.show("Titles successfully generated!");
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 3000);

                // Reset form fields after success
                setNTitles("");
                setPattern("");
                setExamples(["", "", ""]);
                setHasTouchedExamples([]);

                setTimeout(() => {
                    navigate('/title-project');
                }, 3100);
            },
            onError: () => {
                setTriggerQuery(false);
                setShowLoading(false);
                errorAlertRef.current?.show("Failed to generate title");
                setTimeout(() => {
                    errorAlertRef.current?.close();
                }, 3000);
            }
        }
    );


    const validatePattern = (value: string) => {
        const curlyBracketRegex = /\{[^{}]+\}/g;
        const matches = value.match(curlyBracketRegex);

        // Check if there are unmatched or invalid curly braces
        const openBraces = (value.match(/\{/g) || []).length;
        const closeBraces = (value.match(/\}/g) || []).length;

        return matches !== null && matches.length > 0 && openBraces === closeBraces;
    };

    const handlePatternChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPattern(value);
        setIsPatternValid(validatePattern(value)); // Validate the input
    };

    const handleExampleChange = (index: number, value: string) => {
        const updatedExamples = [...examples];
        updatedExamples[index] = value;
        setExamples(updatedExamples);
    };

    const areFirstThreeFilled = examples.slice(0, 3).every((example) => example.trim() !== "");

    return (
        <>
            {showSurvey && <Survey />}
            <div className="ps-container">
                <div className={"seo-project-header"}>
                    <svg className={"back-btn seo-back"} onClick={handleBackClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
                        <path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
                    </svg>
                </div>
                <h1 className="ps-header">Programmatic SEO Title Generator</h1>
                <div className="seo-form-container">
                    {/* <h2>Generate SEO-Optimized Titles</h2> */}
                    <form onSubmit={handleSubmit} className="seo-form">
                        <div className="form-group bottom-space">
                            {/* <label className="seo-text" htmlFor="nTitles">Total Number of Titles</label> */}
                            <h4 className="" style={{color:'#3F77F8', textAlign:'center', fontSize:'1.3em'}}>
                            Enter Title Pattern to Generate Multiple Titles.<br/>
                            use &#123;&#125; to define any subject in the title.<br/>
                            Example: Hire &#123;Designer Role&#125; in &#123;location&#125;
                            </h4>
                            <TextField id="pattern" label={"Title Pattern"} variant="outlined" sx={{ width: isSmallScreen ? 270 : 375 }} value={pattern} onChange={handlePatternChange} />
                             {!isPatternValid && (
                                <small className="error" style={{ color: "red", alignSelf: "center" }}>
                                    Please include at least one valid placeholder in the format {"{example}"}. Ensure text is inside the curly braces.
                                </small>
                            )}
                        </div>
                        <div className="form-group" >
                            {/* <label className="seo-text">Examples (at least 3)</label> */}
                            <h4 className=""  style={{color:'#3F77F8', textAlign:'center', fontSize:'1.3em'}}>
                            Provides a few example of Titles.<br/>
                            Example: Hire Graphics Designer in San Francisco
                            </h4>
                            <div className="form-group-input-field">
                            {examples.map((example, index) => (
                                <div className="abcd">
                                 <div key={index} className={`example-input-container bottom-space ${index >= 1 ? "extras" : ""}`}>
                                 <TextField  label={examples.length === 1 ? "Example" : `Example ${index + 1}`} variant="outlined" sx={{ width: isSmallScreen ? 270 : 375 }} value={example} onChange={(e) => handleExampleChange(index, e.target.value)} onBlur={() => handleBlur(index)} error={hasTouchedExamples[index] && example.trim() === ""} helperText={hasTouchedExamples[index] && example.trim() === "" ? "This field is required" : ""}/>
                                 {index >= 0 && (
                                     <button
                                         type="button"
                                         className="remove-btn"
                                         onClick={() => handleRemoveExample(index)}
                                     >&times;</button>
                                 )}
                                     </div>
                                </div>
                            ))}
                            </div>
                            <div className="form-group add-more">
                            <button
                                type="button"
                                className="add-btn"
                                onClick={handleAddExample}
                                disabled={!areFirstThreeFilled}
                            >
                                + Add More
                            </button>
                            </div>
                        </div>
                        <div className="form-group bottom-space">
                            <h4 className=""  style={{color:'#3F77F8', textAlign:'center', fontSize:'1.3em'}}>
                            How many Titles to generate?<br/>
                            &#123;Your Credit won't get deducted&#125;
                            </h4>
                            <TextField id="nTitles" label={"Total Articles Titles to Generate"} variant="outlined"  sx={{ width: isSmallScreen ? 270 : 375 }} value={nTitles} onChange={handleInputChange} />
                           {!isValid && (
                                <small className="error" style={{ color: "red", alignSelf: "center" }}>Please enter a number greater than zero.</small>
                            )}
                        </div>
                        <div style={{textAlign:'center'}}>
                        <button type="submit" className="submit-btn" disabled={!isValid || !nTitles || !validateExamples()} >
                            PROCEED
                        </button>
                        </div>
                        {(isLoading || isFetching) && showLoading && (
                            <AbunModal active={showLoading}
                                        headerText={""}
                                        modelWidth="450px"
                                        closeable={false}
                                        hideModal={() => {
                                            setShowLoading(false)
                                        }}
                                    >
                                        <div className="loader-container" aria-live="polite" aria-busy={isLoading}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '30px'
                                            }}>
                                            {/* ldrs loader */}
                                            <l-quantum size="60" speed="1.75" color="#3F77F8"></l-quantum>
                                        </div>
                                        <h3 className="modal-header-text">Please hold on</h3>
                                        <p className="modal-subtext">Processing request. Please wait...</p>
                            </AbunModal>
                        )}
                        {error && showLoading && (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "1.3rem",
                                }}
                            >
                                An error occurred.
                                <img
                                    src="/static/media/spinner.a81353c0a4707f9915a4835a359de143.svg"
                                    alt="spinner"
                                    className="spinner-spin ml-5"
                                    style={{
                                        width: "1.4em",
                                        height: "auto",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </p>
                        )}
                    </form>
                    <SuccessAlert ref={successAlertRef} />
                    <ErrorAlert ref={errorAlertRef} />
                </div>
            </div>
        </>
    );
};

export default ProgrammaticSeo;