import "./Survey.scss";

import { useState } from "react";
import GenericButton from "../GenericButton/GenericButton";
import AbunModal from "../AbunModal/AbunModal";
import { Input } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { postSurvey } from "../../utils/api";
import { useNavigate } from "react-router-dom";

interface SurveyQuestion {
    question: string;
    options: string[];
    useCheckboxes: boolean;
}

interface UserAnswer {
    question: string;
    answer: string;
    answers: string[];
}

export default function Survey() {
    // ------------------- Mutations -------------------
    const postSurveyMutation = useMutation(postSurvey);
    const navigate = useNavigate();

    const refreshData = () => {
        const timestamp = new Date().getTime(); // Unique value for each call
        navigate(`/?_=${timestamp}`, { replace: true });
    };
    


    // ------------------------ CONSTANTS ------------------------
    const allSurveyQuestions = [
        {
            question: "What defines you the best?",
            options: ["Content Writer", "SEO Specialist", "Digital Marketer", "Business Owner/Founder", "Other (please specify)"],
            useCheckboxes: false,
        },
        {
            question: "How long have you been creating content or managing SEO?",
            options: ["Less than 6 months", "6 months - 1 year", "1 - 3 years", "More than 3 years"],
            useCheckboxes: false,
        },
        {
            question: "Have you used any other AI writing tools before?",
            options: ["Yes (Except ChatGPT)", "No"],
            useCheckboxes: false,
        },
        {
            question: "Are you planning to use Abun for your new website or an existing one?",
            options: ["New Website", "Existing Website"],
            useCheckboxes: false,
        },
        {
            question: "Which features are you most interested in? (Select all that apply)",
            options: ["Keyword Research", "Article Generation", "Automated Blogging", "Integration with other platforms", "Other (please specify)"],
            useCheckboxes: true,
        }
    ]

    // ------------------------ STATES ------------------------
    const [surveyError, setSurveyError] = useState("");
    const [surveySuccess, setSurveySuccess] = useState("");
    const [surveyActive, setSurveyActive] = useState(true);
    const [surveyQuestion, setSurveyQuestion] = useState<SurveyQuestion>(allSurveyQuestions[0]);
    const [userAnswers, setUserAnswers] = useState<Array<UserAnswer>>([]);
    const [userAnswerInput, setUserAnswerInput] = useState("");
    const [userAnswerInputOther, setUserAnswerInputOther] = useState("");
    const [selectedAnswers, setSelectedAnswers] = useState<Array<string>>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [postingSurvey, setPostingSurvey] = useState(false);

    // ------------------------ FUNCTIONS ------------------------
    function startSurvey() {
        if (surveyQuestion) {
            return (
                <>
                    <div className={"block"}>
                        <p className={"has-text-centered mb-4"} style={{ fontWeight: "600" }}>
                            {currentQuestionIndex + 1}. {surveyQuestion.question}
                        </p>
                    </div>
                    <div className={"block"}>
                        <div className={"is-flex is-flex-direction-row is-justify-content-space-between"} style={{ flexWrap: "wrap" }}>
                            {surveyQuestion.options.map((option, index) => {
                                const optionId = `${surveyQuestion.question.replace(/\s+/g, "-")}-${index}`;
                                const questionName = surveyQuestion.question.replace(/\s+/g, "-");
                                return (
                                    <div className="mb-2" key={index} style={{ flex: "0 0 50%", boxSizing: "border-box" }}>
                                        <label className="survey-option" htmlFor={optionId}>
                                            {
                                                surveyQuestion.useCheckboxes ?
                                                    <input
                                                        type={`checkbox`}
                                                        className="mr-2"
                                                        name={questionName}
                                                        id={optionId}
                                                        value={option}
                                                        onChange={(e) => {
                                                            setSelectedAnswers((prevSelectedAnswers) => {
                                                                if (e.target.checked) {
                                                                    const updatedSelectedAnswers = new Set([...prevSelectedAnswers, e.target.value]);
                                                                    return Array.from(updatedSelectedAnswers);
                                                                } else {
                                                                    const updatedSelectedAnswers = new Set([...prevSelectedAnswers]);
                                                                    updatedSelectedAnswers.delete(e.target.value);
                                                                    return Array.from(updatedSelectedAnswers);
                                                                }
                                                            });
                                                            setSurveyError("");
                                                        }}
                                                    /> :
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        name={questionName}
                                                        id={optionId}
                                                        value={option}
                                                        checked={userAnswerInput === option}
                                                        onChange={(e) => {
                                                            setUserAnswerInput(e.target.value);
                                                            setSurveyError("");
                                                        }}
                                                    />
                                            }
                                            {option}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        {(userAnswerInput === "Other (please specify)" || selectedAnswers.includes("Other (please specify)")) &&
                            <div className={"mt-2"}>
                                <Input type={"text"} onChange={(e) => {
                                    setUserAnswerInputOther(e.target.value);
                                }} />
                            </div>
                        }
                    </div>
                    <GenericButton text={currentQuestionIndex === allSurveyQuestions.length - 1 ? postingSurvey ? "Submitting..." : "Submit" : "Next"}
                        type={"success"}
                        disable={postingSurvey}
                        additionalClassList={["mt-6", "pl-6", "pr-6", "is-fullwidth"]}
                        clickHandler={() => {
                            if (
                                (userAnswerInput === "Other (please specify)" || selectedAnswers.includes("Other (please specify)")) &&
                                userAnswerInputOther.trim() === ""
                            ) {
                                setSurveyError("Please specify your answer");
                                return;
                            } else if (userAnswerInput.trim() === "" && selectedAnswers.length === 0) {
                                setSurveyError("Please select an option");
                                return;
                            }

                            // Prepare the new answer object
                            let newAnswer: UserAnswer;
                            if (userAnswerInput === "Other (please specify)" || selectedAnswers.includes("Other (please specify)")) {
                                const updatedSelectedAnswers = userAnswerInputOther
                                    ? [...selectedAnswers, userAnswerInputOther]
                                    : selectedAnswers;
                                newAnswer = {
                                    question: surveyQuestion.question,
                                    answer: userAnswerInputOther || "",
                                    answers: updatedSelectedAnswers,
                                };
                            } else {
                                newAnswer = {
                                    question: surveyQuestion.question,
                                    answer: userAnswerInput,
                                    answers: selectedAnswers,
                                };
                            }

                            // Compute updated userAnswers
                            const updatedUserAnswers = [
                                ...userAnswers,
                                newAnswer,
                            ];

                            // Update userAnswers state
                            setUserAnswers(updatedUserAnswers);

                            // Reset inputs and errors
                            setUserAnswerInput("");
                            setUserAnswerInputOther("");
                            setSurveyError("");

                            if (currentQuestionIndex < allSurveyQuestions.length - 1) {
                                // Move to the next question
                                const nextQuestionIndex = currentQuestionIndex + 1;
                                setCurrentQuestionIndex(nextQuestionIndex);
                                setSurveyQuestion(allSurveyQuestions[nextQuestionIndex]);
                            } else {
                                setPostingSurvey(true);

                                let name: string | null = null;
                                let email: string | null = null;
                                let signupDate: string | null = null;
                                let plan: string | null = null;
                                let verified: boolean | null = null;
                                let userID: string | null = null;
                                let index: number = 0;
                                let surveyAnswers: any[] = [];

                                for (const answer of updatedUserAnswers) {
                                    surveyAnswers[index] = surveyAnswers[index] || {};

                                    if (answer.answers.length > 0) {
                                        // Remove the "Other (please specify)" selected answer if it exists
                                        const otherAnswerIndex = answer.answers.indexOf("Other (please specify)");
                                        if (otherAnswerIndex !== -1) {
                                            answer.answers.splice(otherAnswerIndex, 1);
                                        }
                                        surveyAnswers[index][answer.question] = answer.answers.join(", ");
                                    } else {
                                        surveyAnswers[index][answer.question] = answer.answer;
                                    }
                                    index++;
                                }

                                // Send the survey to encharge.io & backend
                                postSurveyMutation.mutate(surveyAnswers, {
                                    onSuccess: (data) => {
                                        const response = data['data'] as any;
                                        email = response.email;
                                        signupDate = response.signup_on;
                                        plan = response.plan_name;
                                        name = response.name;
                                        verified = response.verified;
                                        userID = response.user_id;

                                        if (response.success) {
                                            const enchargeURL = "https://api.encharge.io/v1/hooks/0a05aa44-cfa3-4732-839b-3bf502641fcf";
                                            fetch(enchargeURL, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                    name: name,
                                                    user_id: userID,
                                                    email: email,
                                                    verified: verified,
                                                    signup_on: signupDate,
                                                    plan: plan,
                                                    survey: surveyAnswers,
                                                }),
                                            })
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        setSurveySuccess("Thank you for your feedback! We'll use it to improve Abun.");
                                                        refreshData()
                                                        setTimeout(() => {
                                                            setSurveyActive(false);
                                                        }, 1000);
                                                    } else {
                                                        setPostingSurvey(false);
                                                        console.error("Error sending survey to encharge.io");
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.error("Error sending survey to encharge.io:", error);
                                                });
                                        } else {
                                            setPostingSurvey(false);
                                            setSurveyError("Oops! Something went wrong :( Please try again later or contact us on live support.");
                                        }
                                    },
                                    onError: () => {
                                        setPostingSurvey(false);
                                        setSurveyError("Oops! Something went wrong :( Please try again later or contact us on live support.");
                                    }
                                });
                            }
                        }} />

                    <p className={"has-text-danger mt-4"}>{surveyError}</p>
                    <p className={"has-text-success mt-4"}>{surveySuccess}</p>
                </>
            )
        }
    }

    return (
        <>
            <AbunModal active={surveyActive}
                headerText={"⚡ Why are you using Abun?"}
                closeable={false}
                hideModal={() => {
                    setSurveyActive(false);
                }}>
                {startSurvey()}
            </AbunModal>
        </>
    )
}