import { useEffect } from "react";
import NewAuthPageNavbar from "./../components/NewAuthPageNavbar/NewAuthPageNavbar";

import "./errorPage.scss";


export default function MaintenancePage() {

  useEffect(() => {
		document.title = "Abun | Maintenance"
	}, []);

  return (
    <section className={"error-page"}>
    <NewAuthPageNavbar/>
    <div className={"card px-6 has-text-centered"}>
      <div className={"error-icon"}>🛠️</div>
      <h1 className={"is-size-3 has-text-weight-bold"}>{`Site Undergoing Upgrades`}</h1>
      <p className={"has-text-centered"}>
        We’ll be back shortly. Thanks for your patience!
      </p>
    </div>
    </section>
    );
}
